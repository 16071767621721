html[data-color="white"] .main-background {
  background-color: #f5f5f5; }

html[data-color="white"] .poll_link {
  color: #222; }

html[data-color="white"] .poll-form {
  color: #222; }

html[data-color="white"] .poll-form-element__input:checked ~ .poll-form-element__circle::before {
  background-color: #222; }

html[data-color="black"] .main-background {
  background-color: #000; }

html[data-color="black"] .poll_link {
  color: #fff; }

html[data-color="black"] .poll-form {
  color: #fff; }

html[data-color="black"] .poll-form-element__input:checked ~ .poll-form-element__circle::before {
  background-color: #fff; }
