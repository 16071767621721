@charset "UTF-8";
/* Переменные со значениями для разных цветовых схем */
/* Границы блоков */
/* "основной" фон */
/* "Дополнительный" фон, чтобы выделять блоки на фоне основного */
/* Ссылки */
/* Текст */
/* Подчеркивания ссылок */
/* Инвертированный текст */
/* Инвертированный фон */
/* Фон, цвет текста и outline границы элементов в фокусе */
/* Фон и цвет текста элементов при наведении */
/**
 Миксин для назначения свойству различных значений для разных цветовых схем.
 Входные аргументы:
    $property - имя свойства
    $values - Значения свойства в виде асоциативного массива вида (white: ..., black: ..., blue: ..., beige: ..., brown: ...)
 По-умаолчанию значение свойства указывается как значения для white цветовой схемы.
 Далее для всех указанных цветовых схем генерируются правила.
 В $values не обязательно указывать все цветовые схемы, но white - обязательна
 */
/*
	Установить размер шрифта относительно базового.
 	$fontSize - Желаемое значение шрифта в px по-умолчанию.
 	$defaultFontSize - Базовый размер шрифта в px, относительно которого будет выполняться преобразование
 	В результате значение установится как относительное в rem, так, чтобы в пересчете на px получилось $fontSize.
 	Например для $fontSize = 20 (пикселей) и $defaultFontSize = 16 (пикселей) размер шрифта будет 1.25rem,
 	что в пересчете соответствует 20px.
 */
/* Стили для активного элемента (навигация, табы) */
