@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.paging {
  border: 1px solid #dde1e6;
  border-radius: 4px; }
  .paging__left {
    border-right: 1px solid #dde1e6;
    line-height: 3rem; }
  .paging__right {
    border-left: 1px solid #dde1e6;
    line-height: 3rem; }
  .paging__item {
    line-height: 3rem;
    padding: 0 0.75rem; }
    .paging__item.current {
      box-shadow: inset 0 -3px 0 0 #efebe2; }
