html[data-color="white"] .news-form {
  background-color: #f5f5f5; }
  html[data-color="white"] .news-form__success {
    color: #2c2c2c; }

html[data-color="black"] .news-form {
  background-color: #000;
  color: #ccc; }
  html[data-color="black"] .news-form__success {
    color: #fff; }
