@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.logo {
  padding-top: 70px;
  flex-direction: column; }
  .logo__img {
    margin-bottom: 15px; }
  .logo__title {
    position: relative;
    margin: 0;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: normal;
    flex-direction: column;
    overflow: hidden;
    align-items: center; }
  .logo__title span {
    display: inline;
    background: #452b1e;
    padding: 0 30px 10px 30px;
    color: #fff; }
    .logo__title span:first-child {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 36px;
      padding: 15px 30px; }
      @media (max-width: 576px) {
        .logo__title span:first-child {
          font-size: 20px; } }
      @media (max-width: 364px) {
        .logo__title span:first-child {
          font-size: 18px; } }
  .logo__subtitle {
    font-size: 1rem;
    line-height: 1rem;
    width: 200px; }
    @media all and (max-width: 575px) {
      .logo__subtitle {
        font-size: 14px; } }
