html[data-color="white"] .radiobox-label:hover .radiobox-text,
html[data-color="white"] .radiobox-input:focus ~ .radiobox-text {
  color: #555 !important; }

html[data-color="white"] .radiobox-text::before {
  background-color: #f5f5f5 !important;
  border-color: #aaa; }

html[data-color="white"] .radiobox-label:hover .radiobox-text::before,
html[data-color="white"] .radiobox-input:checked ~ .radiobox-text::before {
  background-color: #555 !important;
  border-color: none; }

html[data-color="white"] .radiobox-label:hover .radiobox-text::after,
html[data-color="white"] .radiobox-input:checked ~ .radiobox-text::after {
  background-color: #f5f5f5 !important;
  border-color: none;
  opacity: 1; }

html[data-color="white"] .radiobox-input:focus ~ .radiobox-text::before {
  background-color: #fff02a !important;
  border: 2px solid #0000ff; }

html[data-color="white"] .radiobox-input:focus ~ .radiobox-text::after {
  background-color: #0000ff !important;
  border: none; }

html[data-color="black"] .radiobox-input:focus {
  outline: none !important; }

html[data-color="black"] .radiobox-text::before {
  background-color: #000 !important;
  border-color: #ccc; }

html[data-color="black"] .radiobox-label:hover .radiobox-text,
html[data-color="black"] .radiobox-input:focus ~ .radiobox-text {
  color: #ccc !important; }

html[data-color="black"] .radiobox-label:hover .radiobox-text::before,
html[data-color="black"] .radiobox-input:checked ~ .radiobox-text::before {
  border-color: #ccc; }

html[data-color="black"] .radiobox-label:hover .radiobox-text::after,
html[data-color="black"] .radiobox-input:checked ~ .radiobox-text::after {
  border-color: #fff;
  opacity: 1; }

html[data-color="black"] .radiobox-input:focus ~ .radiobox-text::before {
  background-color: #fff02a !important;
  border: 2px solid #0000ff; }

html[data-color="black"] .radiobox-input:focus ~ .radiobox-text::after {
  background-color: #0000ff !important;
  border: none; }

html[data-color="black"] .radiobox-input:checked:focus ~ .radiobox-text::after {
  border-color: #000; }
