@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.card-info-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .small-size .card-info-icon {
    top: 50%;
    transform: translateY(-50%); }

.card.small-size {
  min-height: 3rem; }
  .card.small-size .card-body {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }

.card-img-overlay {
  background: rgba(0, 0, 0, 0.35); }

@media all and (max-width: 991px) {
  .card-img-top._maxw {
    max-width: 400px; } }

.card-body {
  border-radius: 4px;
  padding: 10px; }

.card {
  position: relative;
  /* Стрелочка в верхнем правом углу карточки, символизирующая ссылку */ }
  .card--ext-link:after {
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    height: 0.75rem;
    width: 0.75rem;
    background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E %3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 335.337 335.337' style='enable-background:new 0 0 335.337 335.337;' xml:space='preserve' fill='%23C7CCD1'%3E %3Cg%3E %3Cg%3E %3Cpath d='M303.193,0H76.153C58.479,0.008,44.159,14.341,44.167,32.014c0.001,1.819,0.157,3.634,0.466,5.426 C47.811,53.196,61.844,64.395,77.913,64h124.8c6.571-0.013,13.101-1.039,19.36-3.04l10.56-3.36L10.393,279.84 c-11.653,11.124-13.611,29.019-4.64,42.4c10.441,14.259,30.464,17.355,44.724,6.914c1.152-0.844,2.247-1.764,3.276-2.754 l223.84-223.2l-2.56,7.04c-2.532,6.976-3.831,14.339-3.84,21.76v125.28c-0.395,16.069,10.804,30.101,26.56,33.28 c17.416,3.006,33.971-8.675,36.977-26.09c0.337-1.951,0.492-3.93,0.463-5.91V32C335.193,14.327,320.866,0,303.193,0z'/%3E %3C/g%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
