@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.poll {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #452b1e;
  color: #F4F7FB; }
  .poll__img {
    float: left;
    display: block;
    margin-right: 40px; }
  .poll__btn {
    background: #a31d24;
    color: #F4F7FB; }
  .poll__link {
    color: inherit; }
  .poll-form {
    padding: 20px;
    border-radius: 3px;
    border: none; }
    @media (min-width: 1200px) {
      .poll-form {
        border: 1px solid #dadadb; } }
    @media (min-width: 992px) {
      .poll-form {
        padding: 50px 50px 35px 42px; } }
    @media (min-width: 576px) {
      .poll-form {
        padding: 30px; } }
    .poll-form__question {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.2; }
      @media (min-width: 576px) {
        .poll-form__question {
          font-size: 1.5rem; } }
    .poll-form__info {
      font-size: .75rem;
      font-weight: 300; }
      @media (min-width: 576px) {
        .poll-form__info {
          font-size: 1.125rem; } }
    .poll-form__radio {
      position: relative;
      height: 100%;
      padding-left: 0; }
      @media (min-width: 992px) {
        .poll-form__radio {
          padding-left: 90px; } }
    .poll-form__results {
      display: block;
      background: #d1c4a8; }
      @media (min-width: 576px) {
        .poll-form__results {
          display: inline-block; } }
    .poll-form_mb-36 {
      margin-bottom: 36px; }
    .poll-form-element {
      position: relative;
      padding-left: 30px; }
      @media (min-width: 576px) {
        .poll-form-element {
          padding-left: 40px; } }
      .poll-form-element__input {
        position: absolute;
        z-index: -1;
        opacity: 0; }
        .poll-form-element__input:checked ~ .poll-form-element__circle::before {
          content: '';
          display: block;
          position: absolute;
          left: .5625rem;
          top: .5625rem;
          width: .75rem;
          height: .75rem;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: #efebe2; }
      .poll-form-element__label {
        font-size: 1rem;
        font-weight: 300; }
        @media (min-width: 576px) {
          .poll-form-element__label {
            font-size: 1.125rem; } }
      .poll-form-element__circle {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid #fff;
        border-radius: 50%;
        background-color: transparent; }

.vertical-line {
  position: absolute;
  top: -1.5rem;
  bottom: 0;
  left: 0;
  transform: translate(0, -50%);
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3); }
  @media (min-width: 992px) {
    .vertical-line {
      top: 0;
      left: 45px;
      transform: translate(-50%, 0);
      width: 3px;
      height: 100%; } }

.btn_ml-40 {
  margin-left: 0; }
  @media (min-width: 576px) {
    .btn_ml-40 {
      margin-left: 40px; } }
