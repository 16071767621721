.normative p {
  max-width: 90ch;
  margin-bottom: .7rem; }
  .normative p:last-child {
    margin-bottom: 0; }

.npa-search__span {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.npa-search__first-row, .npa-search__second-row {
  display: block; }
  @media (min-width: 420px) {
    .npa-search__first-row, .npa-search__second-row {
      display: flex;
      justify-content: space-between; } }

.npa-search__input_first-row, .npa-search__input_second-row {
  width: 100%; }
  @media (min-width: 420px) {
    .npa-search__input_first-row, .npa-search__input_second-row {
      width: 50%; } }
  .npa-search__input_first-row:first-child, .npa-search__input_second-row:first-child {
    width: 100%;
    margin-bottom: .5rem; }
    @media (min-width: 420px) {
      .npa-search__input_first-row:first-child, .npa-search__input_second-row:first-child {
        width: 49%;
        margin-bottom: 0; } }

.npa-search__button_normatives {
  width: 100%; }

.npa-table__btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 0;
  line-height: 0;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid #F4F7FB;
  border-radius: 4px;
  background-color: #3B4256; }
  .npa-table__btn:hover, .npa-table__btn_active {
    background-color: #fff;
    border: 1px solid #3b4256; }

.npa-table__btn-eye {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23fff'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E"); }
  .npa-table__btn-eye:hover, .npa-table__btn-eye_active {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%233b4256' stroke='%23fff'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E"); }

.npa-table__btn-download {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E %3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 342.832 342.832' style='enable-background:new 0 0 342.832 342.832;' xml:space='preserve' fill='%23fff' stroke='%23fff'%3E %3Cg%3E %3Cg%3E %3Cg%3E %3Cpath d='M276.326,232.386c-1.459-1.574-3.499-2.481-5.645-2.509h-52.245v-125.91c0-4.702-1.045-9.927-5.224-9.927H129.62 c-5.301,0.621-9.557,4.664-10.449,9.927v125.91h-47.02c-4.328,0.057-7.79,3.611-7.733,7.939c0.028,2.146,0.935,4.186,2.509,5.645 l99.265,97.176c2.827,2.885,7.458,2.933,10.343,0.106c0.036-0.035,0.071-0.07,0.106-0.106l99.265-97.176 C279.08,240.519,279.268,235.561,276.326,232.386z'/%3E %3Cpath d='M129.62,78.367h83.592c4.328,0,7.837-3.509,7.837-7.837s-3.509-7.837-7.837-7.837H129.62 c-4.328,0-7.837,3.509-7.837,7.837S125.292,78.367,129.62,78.367z'/%3E %3Cpath d='M129.62,47.02h83.592c4.328,0,7.837-3.509,7.837-7.837s-3.509-7.837-7.837-7.837H129.62 c-4.328,0-7.837,3.509-7.837,7.837S125.292,47.02,129.62,47.02z'/%3E %3Cpath d='M129.62,15.673h83.592c4.328,0,7.837-3.509,7.837-7.837c0-4.328-3.509-7.837-7.837-7.837H129.62 c-4.328,0-7.837,3.509-7.837,7.837C121.783,12.165,125.292,15.673,129.62,15.673z'/%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E"); }
  .npa-table__btn-download:hover, .npa-table__btn-download_active {
    background-image: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E %3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 342.832 342.832' style='enable-background:new 0 0 342.832 342.832;' xml:space='preserve' fill='%233b4256' stroke='%23fff'%3E %3Cg%3E %3Cg%3E %3Cg%3E %3Cpath d='M276.326,232.386c-1.459-1.574-3.499-2.481-5.645-2.509h-52.245v-125.91c0-4.702-1.045-9.927-5.224-9.927H129.62 c-5.301,0.621-9.557,4.664-10.449,9.927v125.91h-47.02c-4.328,0.057-7.79,3.611-7.733,7.939c0.028,2.146,0.935,4.186,2.509,5.645 l99.265,97.176c2.827,2.885,7.458,2.933,10.343,0.106c0.036-0.035,0.071-0.07,0.106-0.106l99.265-97.176 C279.08,240.519,279.268,235.561,276.326,232.386z'/%3E %3Cpath d='M129.62,78.367h83.592c4.328,0,7.837-3.509,7.837-7.837s-3.509-7.837-7.837-7.837H129.62 c-4.328,0-7.837,3.509-7.837,7.837S125.292,78.367,129.62,78.367z'/%3E %3Cpath d='M129.62,47.02h83.592c4.328,0,7.837-3.509,7.837-7.837s-3.509-7.837-7.837-7.837H129.62 c-4.328,0-7.837,3.509-7.837,7.837S125.292,47.02,129.62,47.02z'/%3E %3Cpath d='M129.62,15.673h83.592c4.328,0,7.837-3.509,7.837-7.837c0-4.328-3.509-7.837-7.837-7.837H129.62 c-4.328,0-7.837,3.509-7.837,7.837C121.783,12.165,125.292,15.673,129.62,15.673z'/%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E"); }

.npa_block {
  margin-top: 20px; }

.npa_view {
  width: 100%;
  min-height: 500px; }
