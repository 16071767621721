@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.news-form {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px; }
  .news-form__title {
    margin: 0 0 .5rem;
    font-size: 1.125rem; }
  .news-form__input {
    display: block;
    width: 240px; }
    @media (min-width: 400px) {
      .news-form__input {
        width: auto;
        display: inline-block; } }
  .news-form__select {
    display: inline-block;
    width: auto; }
    .news-form__select_padding_6 {
      padding: 6px; }
  .news-form__btn {
    margin-top: -1px;
    width: 240px; }
    @media (min-width: 400px) {
      .news-form__btn {
        width: auto; } }
  .news-form_border_left {
    border-left: none; }
    @media (min-width: 768px) {
      .news-form_border_left {
        border-left: 1px solid rgba(0, 0, 0, 0.125); } }
