@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.footer .footer__copyright-studio {
  float: right; }

.footer .footer__copyright-15web {
  margin-left: 5px;
  padding: 0 0 2px 26px;
  background: url(img/i-15web.png) no-repeat 0 50%; }

.footer .footer__copyright-15web a span {
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

.footer__references {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
  width: auto;
  list-style: none; }
  @media (min-width: 768px) {
    .footer__references {
      text-align: left; } }
  .footer__references:first-child {
    margin-left: 15px; }
    @media (max-width: 768px) {
      .footer__references:first-child {
        margin-left: 0; } }
    @media (min-width: 880px) {
      .footer__references:first-child {
        margin-right: 30px; } }

.footer__reference {
  min-width: 130px; }
  .footer__reference-link {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #333333;
    line-height: 1rem; }
    .footer__reference-link:hover {
      border-bottom: none;
      color: inherit; }
