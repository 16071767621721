html[data-color="white"] .news-item .card-body {
  background-color: #f5f5f5;
  color: #555; }

html[data-color="black"] .news-item .card-body {
  background-color: #000;
  color: #ccc; }

html[data-images="off"] .news-item .card-body img {
  display: none; }
