html[data-color="white"] .general-info {
  background-color: #fff;
  color: #555; }
  html[data-color="white"] .general-info .card-body {
    background-color: #f5f5f5;
    color: #555; }

html[data-color="white"] .gallery {
  background-color: #f5f5f5;
  color: #555; }

html[data-color="white"] .links .card {
  background-color: #fff; }

html[data-color="black"] .general-info {
  background-color: #1d1d1d;
  color: #ccc; }
  html[data-color="black"] .general-info .card-body {
    background-color: #000;
    color: #ccc; }

html[data-color="black"] .gallery {
  background-color: #000;
  color: #ccc; }

html[data-color="black"] .links .card {
  background-color: #1d1d1d; }

html:not([data-color="default"]) .news__link {
  border: none; }
