html[data-color="white"] .menu > li > [role=menu] {
  background-color: #f5f5f5;
  color: #555; }

html[data-color="white"] .menu > li:hover {
  background: #fff; }

html[data-color="white"] .menu__dropdown {
  background: #fff; }
  html[data-color="white"] .menu__dropdown a:hover {
    background: #fff; }

html[data-color="white"] .sidebar-nav li:hover {
  background: #f5f5f5; }

html[data-color="white"] .sidebar-nav__dropdown {
  background: #f5f5f5; }

html[data-color="white"] .burger span {
  background-color: #555; }

html[data-color="white"] .menu__item:focus .burger span {
  background-color: #111; }

html[data-color="black"] .menu > li > [role=menu] {
  background-color: #000;
  color: #ccc; }

html[data-color="black"] .burger span {
  background-color: #ccc; }

html[data-color="black"] .menu__item:focus .burger span {
  background-color: #111; }

html[data-color="black"] .menu > li:hover {
  background-color: #000;
  color: #ccc; }

html[data-color="black"] .menu__dropdown {
  background-color: #000;
  color: #ccc; }
  html[data-color="black"] .menu__dropdown a:hover {
    background-color: #000;
    color: #ccc; }

html[data-color="black"] .sidebar-nav li:hover {
  background-color: #000;
  color: #ccc; }

html[data-color="black"] .sidebar-nav__dropdown {
  background-color: #000;
  color: #ccc; }

html[data-color="black"] .menu__item:hover {
  color: #ccc !important; }

html:not([data-color="default"]) .menu__item {
  min-height: 50px;
  height: 100%; }

html:not([data-color="default"]) .menu__item:hover, html:not([data-color="default"]) .menu__item:focus {
  box-shadow: none; }

html[data-fontsize="big"] .menu > li > [role=menu] {
  top: 173px; }

html[data-fontsize="big"] .wcag_visible .menu > li > [role="menu"] {
  top: 274px; }

html[data-fontsize="large"] .menu > li > [role=menu] {
  top: 179px; }

html[data-fontsize="large"] .wcag_visible .menu > li > [role="menu"] {
  top: 280px; }

html[data-fontsize="large"][data-kerning="big"] .menu > li > [role=menu] {
  top: 199px; }

html[data-fontsize="large"][data-kerning="big"] .wcag_visible .menu > li > [role="menu"] {
  top: 300px; }
