@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.gallery .h2:hover > a {
  color: #848E99;
  text-decoration: none; }

.gallery-page__photo {
  overflow: hidden; }
