@charset "UTF-8";
/* Переменные со значениями для разных цветовых схем */
/* Границы блоков */
/* "основной" фон */
/* "Дополнительный" фон, чтобы выделять блоки на фоне основного */
/* Ссылки */
/* Текст */
/* Подчеркивания ссылок */
/* Инвертированный текст */
/* Инвертированный фон */
/* Фон, цвет текста и outline границы элементов в фокусе */
/* Фон и цвет текста элементов при наведении */
/**
 Миксин для назначения свойству различных значений для разных цветовых схем.
 Входные аргументы:
    $property - имя свойства
    $values - Значения свойства в виде асоциативного массива вида (white: ..., black: ..., blue: ..., beige: ..., brown: ...)
 По-умаолчанию значение свойства указывается как значения для white цветовой схемы.
 Далее для всех указанных цветовых схем генерируются правила.
 В $values не обязательно указывать все цветовые схемы, но white - обязательна
 */
/*
	Установить размер шрифта относительно базового.
 	$fontSize - Желаемое значение шрифта в px по-умолчанию.
 	$defaultFontSize - Базовый размер шрифта в px, относительно которого будет выполняться преобразование
 	В результате значение установится как относительное в rem, так, чтобы в пересчете на px получилось $fontSize.
 	Например для $fontSize = 20 (пикселей) и $defaultFontSize = 16 (пикселей) размер шрифта будет 1.25rem,
 	что в пересчете соответствует 20px.
 */
/* Стили для активного элемента (навигация, табы) */
/* Переменные со значениями для разных цветовых схем */
/* Границы блоков */
/* "основной" фон */
/* "Дополнительный" фон, чтобы выделять блоки на фоне основного */
/* Ссылки */
/* Текст */
/* Подчеркивания ссылок */
/* Инвертированный текст */
/* Инвертированный фон */
/* Фон, цвет текста и outline границы элементов в фокусе */
/* Фон и цвет текста элементов при наведении */
/* Для всех элементов задаем цвет границ, чтобы не менять у каждого элемента в частности */
* {
  border-color: #aaa; }
  html[data-color="white"] * {
    border-color: #aaa !important; }
  html[data-color="black"] * {
    border-color: #ccc !important; }
  html[data-color="blue"] * {
    border-color: #cc0 !important; }
  html[data-color="beige"] * {
    border-color: #444 !important; }
  html[data-color="brown"] * {
    border-color: #aad469 !important; }

body {
  background-color: #fff;
  color: #555; }
  html[data-color="white"] body {
    background-color: #fff; }
  html[data-color="black"] body {
    background-color: #1d1d1d; }
  html[data-color="blue"] body {
    background-color: #06066b; }
  html[data-color="beige"] body {
    background-color: #f7f3d6; }
  html[data-color="brown"] body {
    background-color: #352518; }
  html[data-color="white"] body {
    color: #555; }
  html[data-color="black"] body {
    color: #ccc; }
  html[data-color="blue"] body {
    color: #cc0; }
  html[data-color="beige"] body {
    color: #444; }
  html[data-color="brown"] body {
    color: #aad469; }
  body a {
    color: #222; }
    html[data-color="white"] body a {
      color: #222; }
    html[data-color="black"] body a {
      color: #fff; }
    html[data-color="blue"] body a {
      color: #ff0; }
    html[data-color="beige"] body a {
      color: #111; }
    html[data-color="brown"] body a {
      color: #9bff00; }

html:not([data-color="default"]) a:not([disabled]):not([aria-disabled="true"]):hover,
html:not([data-color="default"]) [tabindex]:not([tabindex="-1"]):hover,
html:not([data-color="default"]) button:not([disabled]):not([aria-disabled="true"]):hover {
  text-decoration: none;
  cursor: pointer; }

html:not([data-color="default"]) body {
  line-height: 1.25; }

html:not([data-color="default"]) [aria-disabled="true"],
html:not([data-color="default"]) [disabled] {
  opacity: 0.6;
  cursor: not-allowed; }

html[data-color="white"] input,
html[data-color="white"] select,
html[data-color="white"] textarea {
  background-color: #fff;
  color: #555; }

html[data-color="white"] a:focus, html[data-color="white"] a:target,
html[data-color="white"] img:focus,
html[data-color="white"] img:target,
html[data-color="white"] [tabindex]:focus,
html[data-color="white"] [tabindex]:target {
  outline: 2px solid #0000ff;
  background-color: #fff02a;
  color: #111; }
  html[data-color="white"] a:focus svg, html[data-color="white"] a:target svg,
  html[data-color="white"] img:focus svg,
  html[data-color="white"] img:target svg,
  html[data-color="white"] [tabindex]:focus svg,
  html[data-color="white"] [tabindex]:target svg {
    fill: #111; }

html[data-color="white"] input:not([type="checkbox"]):focus, html[data-color="white"] input:not([type="checkbox"]):target,
html[data-color="white"] select:focus,
html[data-color="white"] select:target,
html[data-color="white"] textarea:focus,
html[data-color="white"] textarea:target {
  /* Focus стили будут important */
  outline: 2px solid #0000ff !important;
  background-color: #fff02a !important;
  color: #111 !important; }

html[data-color="white"] a:not([disabled]):not([aria-disabled="true"]):not(.btn):hover,
html[data-color="white"] [tabindex]:not([tabindex="-1"]):hover {
  outline: 2px solid #0000ff;
  color: #555; }

html[data-color="black"] input,
html[data-color="black"] select,
html[data-color="black"] textarea {
  background-color: #1d1d1d;
  color: #ccc; }

html[data-color="black"] a:focus, html[data-color="black"] a:target,
html[data-color="black"] input:not([type="checkbox"]):focus,
html[data-color="black"] input:not([type="checkbox"]):target,
html[data-color="black"] select:focus,
html[data-color="black"] select:target,
html[data-color="black"] textarea:focus,
html[data-color="black"] textarea:target,
html[data-color="black"] img:focus,
html[data-color="black"] img:target,
html[data-color="black"] [tabindex]:focus,
html[data-color="black"] [tabindex]:target {
  /* Focus стили будут important */
  outline: 2px solid #ccc !important;
  background-color: #fff02a !important;
  color: #111 !important; }
  html[data-color="black"] a:focus svg, html[data-color="black"] a:target svg,
  html[data-color="black"] input:not([type="checkbox"]):focus svg,
  html[data-color="black"] input:not([type="checkbox"]):target svg,
  html[data-color="black"] select:focus svg,
  html[data-color="black"] select:target svg,
  html[data-color="black"] textarea:focus svg,
  html[data-color="black"] textarea:target svg,
  html[data-color="black"] img:focus svg,
  html[data-color="black"] img:target svg,
  html[data-color="black"] [tabindex]:focus svg,
  html[data-color="black"] [tabindex]:target svg {
    fill: #111; }

html[data-color="black"] a:not([disabled]):not([aria-disabled="true"]):not(.btn):hover,
html[data-color="black"] [tabindex]:not([tabindex="-1"]):hover {
  outline: 2px solid #ccc;
  color: #ccc; }

/* Для блоков через атрибут можно указать альтернативный цвет текста */
[data-wcag="alternative-background"] {
  background-color: #f5f5f5; }
  html[data-color="white"] [data-wcag="alternative-background"] {
    background-color: #f5f5f5; }
  html[data-color="black"] [data-wcag="alternative-background"] {
    background-color: #000; }
  html[data-color="blue"] [data-wcag="alternative-background"] {
    background-color: #0c0c4e; }
  html[data-color="beige"] [data-wcag="alternative-background"] {
    background-color: #fbf2af; }
  html[data-color="brown"] [data-wcag="alternative-background"] {
    background-color: #2b1d11; }

/* При выключенных картинках */
html[data-images="off"] img,
html[data-images="off"] svg {
  visibility: hidden; }

html[data-images="off"] *,
html[data-images="off"] *:before,
html[data-images="off"] *:after {
  background-image: none !important;
  /* !important на случай inline стилей в верстке */ }

/* При увеличенном кернинге */
html[data-kerning="big"] {
  letter-spacing: 0.1rem; }
  html[data-kerning="big"] select,
  html[data-kerning="big"] input,
  html[data-kerning="big"] button,
  html[data-kerning="big"] textarea {
    letter-spacing: inherit; }

/* Конфиги для font-size */
html[data-fontsize="normal"] {
  font-size: 1rem; }

html[data-fontsize="big"] {
  font-size: 1.125rem; }

html[data-fontsize="large"] {
  font-size: 1.25rem; }
