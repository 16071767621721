@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.search-btn {
  margin-top: 2px;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 0;
  line-height: 0;
  border: none;
  background-color: transparent;
  background-image: url(img/search.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  padding: 0; }
