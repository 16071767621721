@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.show-more {
  text-decoration: underline !important; }

@media all and (max-width: 575px) {
  .show-item {
    display: none; } }

@media all and (max-width: 575px) {
  .show-item._show3:nth-child(-n + 3) {
    display: block; } }

@media all and (max-width: 575px) {
  .show-item._show4:nth-child(-n + 4) {
    display: block; } }

@media all and (max-width: 575px) {
  .show-container.active .show-item {
    display: block !important; } }
