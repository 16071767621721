@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
html[data-color="white"] .sidebar-nav {
  background-color: #f5f5f5;
  color: #555;
  border: 1px solid #aaa; }
  html[data-color="white"] .sidebar-nav a:not(.btn) {
    color: #222; }

html[data-color="black"] .sidebar-nav {
  background-color: #000;
  color: #ccc;
  border: 1px solid #ccc; }

html[data-color="default"] .sidebar-nav a:not(.btn) {
  color: #3b2c25; }

html[data-color="default"] .sidebar-nav a:not(.btn):hover,
html[data-color="default"] .sidebar-nav a:not(.btn).current {
  color: #3b2c25; }
