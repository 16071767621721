@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.social {
  padding: 0;
  margin: 0 0 0 150px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 50px;
  align-items: center; }
  .social > li {
    position: relative;
    display: block; }
  .social__link {
    display: block;
    height: 25px;
    width: 25px;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
    opacity: .7; }
    .social__link-insta {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 2834.6 2834.6' style='enable-background:new 0 0 2834.6 2834.6;' xml:space='preserve' fill='%23bd2f78'%3E %3Cg%3E %3Cpath class='st0' d='M1417.3,1659.5c129.9,0,242.1-106.3,242.1-242.1c0-53.1-17.7-100.4-47.2-141.7 c-41.3-59.1-118.1-100.4-194.9-100.4c-76.8,0-153.5,41.3-194.9,100.4c-29.5,41.3-47.2,88.6-47.2,141.7 C1175.2,1553.2,1287.4,1659.5,1417.3,1659.5z'/%3E %3Cpath class='st0' d='M1765.8,1275.6c17.7,41.3,29.5,94.5,29.5,141.7c0,206.7-171.3,378-378,378s-378-171.3-378-378 c0-47.2,11.8-100.4,29.5-141.7H862.2v566.9c0,70.9,59.1,129.9,129.9,129.9h850.4c70.9,0,129.9-59.1,129.9-129.9l0-566.9H1765.8z'/%3E %3Cpolygon class='st0' points='1948.8,885.8 1919.3,885.8 1712.6,885.8 1712.6,1122 1948.8,1122 '/%3E %3Cpath class='st0' d='M1417.3,0C634.6,0,0,634.6,0,1417.3c0,782.8,634.6,1417.3,1417.3,1417.3s1417.3-634.6,1417.3-1417.3 C2834.6,634.6,2200.1,0,1417.3,0z M2108.3,1842.5c0,147.6-118.1,265.7-265.7,265.7H992.1c-147.6,0-265.7-118.1-265.7-265.7V992.1 c0-147.6,118.1-265.7,265.7-265.7h850.4c147.6,0,265.7,118.1,265.7,265.7V1842.5z'/%3E %3C/g%3E %3C/svg%3E"); }
    .social__link-fb {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 253.8 253.8' style='enable-background:new 0 0 253.8 253.8;' xml:space='preserve' fill='%23434f91'%3E %3Ctitle%3Efacebook%3C/title%3E %3Cpath class='st0' d='M126.9,0C56.8,0,0,56.8,0,126.9v0.1c0,70,56.8,126.7,126.8,126.8h0.1c70,0,126.8-56.8,126.8-126.8v-0.1 C253.7,56.8,196.9,0,126.9,0z M166.9,78.8h-14.5c-11.4,0-13.6,5.4-13.6,13.3v17.6h27.1l-3.5,27.4h-23.6v70.3l-28.3-0.1V137H86.8 v-27.4h23.8V89.4c0-23.4,14.3-36.2,35.2-36.2c7,0,14.1,0.4,21.1,1.1V78.8z'/%3E %3C/svg%3E"); }
    .social__link-ok {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 253.8 253.7' style='enable-background:new 0 0 253.8 253.7;' xml:space='preserve' fill='%23f0921c'%3E %3Ctitle%3Eok%3C/title%3E %3Cg id='Layer_2'%3E %3C/g%3E %3Cg%3E %3Cellipse transform='matrix(0.7071 -0.7071 0.7071 0.7071 -30.1534 117.2032)' class='st0' cx='126.4' cy='95' rx='14.8' ry='14.8'/%3E %3Cpath class='st0' d='M126.9,0C56.8,0,0,56.8,0,126.9c0,70,56.8,126.8,126.8,126.8h0.1c70,0,126.8-56.8,126.8-126.8 C253.8,56.8,197,0,126.9,0z M126.4,59.2c19.7,0,35.7,16,35.7,35.7s-16,35.7-35.7,35.7s-35.7-16-35.7-35.7S106.7,59.2,126.4,59.2z M160.8,179.7c0.1,0.1,0.2,0.2,0.3,0.3c4,4.2,3.9,10.8-0.3,14.8c-4.2,4-10.8,3.9-14.8-0.3l-19.6-19.6l-19.6,19.6 c-4.1,3.9-10.5,3.9-14.5,0c-4.1-4-4.3-10.6-0.3-14.8l20-20c-7.3-1.7-14.3-4.6-20.7-8.6c-4.9-3.1-6.4-9.5-3.3-14.4 c3.1-4.9,9.5-6.4,14.4-3.3c14.7,9.2,33.3,9.2,48,0c4.9-3.1,11.3-1.6,14.4,3.3c3.1,4.9,1.6,11.3-3.3,14.4c-6.4,4-13.4,6.9-20.7,8.6 L160.8,179.7z'/%3E %3C/g%3E %3C/svg%3E"); }
    .social__link-vk {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 253.8 253.8' style='enable-background:new 0 0 253.8 253.8;' xml:space='preserve' fill='%23485c7e'%3E %3Ctitle%3Evk%3C/title%3E %3Cpath class='st0' d='M126.9,0C56.8,0,0,56.8,0,126.9v0.1c0,70,56.8,126.7,126.8,126.8c70.1,0,126.9-56.8,126.9-126.8v-0.1 C253.8,56.8,196.9,0,126.9,0z M197,176.9l-21.5,0.3c0,0-4.6,0.9-10.7-3.3c-8-5.5-15.5-19.9-21.5-18c-6,1.9-5.8,14.8-5.8,14.8 s0.2,2.6-1.3,4.2c-1.5,1.6-4.4,1.9-4.4,1.9h-9.6c0,0.1-21.3,1.4-39.8-17.7C62,137.9,44,95.8,44,95.8s-1-2.8,0.1-4.1 c1.3-1.5,4.7-1.6,4.7-1.6l23-0.2c1.3,0.2,2.6,0.8,3.7,1.5c0.9,0.7,1.6,1.7,2,2.7c2.5,6.1,5.3,12.1,8.6,17.9 c9.6,16.5,14,20.2,17.3,18.4c4.7-2.6,3.3-23.5,3.3-23.5s0.1-7.6-2.4-11c-1.9-2.6-5.5-3.4-7.1-3.6c-1.3-0.2,0.8-3.2,3.6-4.5 c4.2-2.1,11.5-2.2,20.1-2.1c6.7,0.1,8.7,0.5,11.3,1.1c7.9,1.9,5.2,9.3,5.2,27c0,5.7-1,13.7,3.1,16.3c1.8,1.2,6.1,0.2,16.9-18.1 c3.4-6.1,6.4-12.4,8.9-18.9c0.4-1,1.2-1.9,2.1-2.6c0.9-0.5,2-0.7,3.1-0.6l24.2-0.2c0,0,7.2-0.9,8.4,2.4c1.2,3.5-2.7,11.5-12.6,24.7 c-16.3,21.6-18.1,19.6-4.6,32.1c12.9,12,15.6,17.8,16,18.5C208.2,176.2,197,176.9,197,176.9z'/%3E %3C/svg%3E"); }
    .social__link-tw {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 253.8 253.8' style='enable-background:new 0 0 253.8 253.8;' xml:space='preserve' fill='%234ba2d9'%3E %3Ctitle%3Efacebook%3C/title%3E %3Cpath class='st0' d='M126.9,0C56.8,0,0,56.8,0,126.9v0.1c0,70,56.8,126.7,126.8,126.8h0.1c70,0,126.8-56.8,126.8-126.8v-0.1 C253.7,56.8,196.9,0,126.9,0z M186.9,101.8c0.1,1.3,0.1,2.7,0.1,4c0,40.9-31.1,88-88,88c-17.5,0-33.7-5.1-47.4-13.9 c2.4,0.3,4.9,0.4,7.4,0.4c14.5,0,27.8-4.9,38.4-13.2c-13.5-0.3-25-9.2-28.9-21.5c1.9,0.4,3.8,0.6,5.8,0.6c2.8,0,5.5-0.4,8.1-1.1 c-14.1-2.8-24.8-15.3-24.8-30.3c0-0.1,0-0.3,0-0.4c4.2,2.3,8.9,3.7,14,3.9c-8.3-5.5-13.8-15-13.8-25.7c0-5.6,1.5-10.9,4.2-15.5 c15.2,18.7,38,31,63.7,32.3c-0.5-2.2-0.8-4.6-0.8-7c0-17.1,13.8-30.9,30.9-30.9c8.9,0,17,3.8,22.6,9.8c7-1.4,13.6-4,19.6-7.5 c-2.3,7.2-7.2,13.3-13.6,17.1c6.3-0.8,12.3-2.4,17.8-4.9C198.1,92.2,192.8,97.6,186.9,101.8z'/%3E %3C/svg%3E"); }
    .social__link-you {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 253.8 253.8' style='enable-background:new 0 0 253.8 253.8;' xml:space='preserve' fill='%23de3122'%3E %3Ctitle%3Eutube%3C/title%3E %3Cg%3E %3Cpath class='st0' d='M126.9,0C56.8,0,0,56.8,0,126.9v0.1c0,70,56.8,126.7,126.8,126.7h0.1c70,0,126.8-56.7,126.8-126.8v-0.1 C253.8,56.8,197,0,126.9,0z M197.8,128c0,10.9,0,22.5-2.4,33.1c-1.6,7.3-7.7,12.8-15.1,13.7c-17.4,1.9-34.9,1.9-52.4,1.9 s-35.1,0-52.4-1.9c-7.4-0.9-13.5-6.4-15.1-13.7C58,150.5,58,139,58,128s0.1-22.5,2.5-33.1c1.6-7.3,7.7-12.8,15.1-13.7 c17.4-1.9,34.9-1.8,52.4-1.9s35.1,0,52.4,1.9c7.4,0.9,13.5,6.4,15.1,13.7C197.9,105.5,197.8,117.1,197.8,128z'/%3E %3Cpolygon class='st0' points='113.4,147 150.4,127.6 113.4,108.2 '/%3E %3C/g%3E %3C/svg%3E"); }
    .social__link-whatsapp {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 510.2 510.2' style='enable-background:new 0 0 510.2 510.2;' xml:space='preserve' fill='%2341ab43'%3E %3Cpath d='M255.1,0C114.2,0,0,114.2,0,255.1s114.2,255.1,255.1,255.1S510.2,396,510.2,255.1S396,0,255.1,0z M255.3,413.9 c-27.3,0-54.1-7-77.8-20.3l-81.8,21l22.3-79.3c-14.4-24.4-21.9-52.1-21.9-80.6c0-87.8,71.4-159.2,159.2-159.2S414.5,167,414.5,254.7 S343.1,413.9,255.3,413.9z'/%3E %3Cpath d='M255.3,122.6c-72.9,0-132.2,59.3-132.2,132.2c0,25.4,7.2,50.1,20.9,71.3l3.3,5.1l-12.9,45.7l47.2-12.2l4.9,3 c20.7,12.6,44.4,19.3,68.8,19.3c72.9,0,132.2-59.3,132.2-132.2S328.2,122.6,255.3,122.6z M336.4,295.4l-1.6,7.7 c-1.9,8.7-7,16.4-14.5,21.2c-9.5,6-21.7,9-37.3,4.6c-48.3-13.7-75.7-45.7-90.3-65.7c-14.7-20-20.3-40-16.7-56.3 c2.5-11,10.5-19.4,15.5-23.7c2.5-2.1,5.6-3.2,8.9-3l10.3,0.6c2,0.1,3.8,1.4,4.6,3.2l15,35.2c0.8,1.9,0.5,4.1-0.9,5.6l-13.1,15.1 c-1.1,1.2-1.2,3-0.5,4.4c17.2,33.2,48.7,46.4,58.1,49.7c1.6,0.6,3.4,0,4.4-1.3l13.7-17.8c1.5-2,4.2-2.6,6.5-1.6l34.9,16.1 C335.7,290.4,336.9,292.9,336.4,295.4z'/%3E %3C/svg%3E"); }
    .social__link-telegram {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 455.7 455.7' style='enable-background:new 0 0 455.7 455.7;' xml:space='preserve' fill='%232aafd4'%3E %3Cg%3E %3Cpath d='M227.9,0C102,0,0,102,0,227.9s102,227.9,227.9,227.9s227.9-102,227.9-227.9S353.8,0,227.9,0z M353.2,122.8l-45,215.3 c-2.1,10.1-13.9,14.6-22.2,8.6l-68-49.9l-34.6,35.4c-6.1,6.2-16.6,3.7-19.2-4.6l-25-79.4L72,228.3c-8.8-2.6-9.3-14.9-0.8-18.2 L335,107.3C345,103.4,355.3,112.2,353.2,122.8z'/%3E %3Cpath d='M298,150l-144.6,89.8c-2.5,1.6-3.7,4.7-2.9,7.5l19.4,62.6c0.4,1.3,2.3,1.1,2.5-0.3l4.7-41.9c0.2-1.6,1-3.1,2.2-4.2 l122.6-108.4C304.8,152.5,301.4,147.9,298,150z'/%3E %3C/g%3E %3C/svg%3E"); }
    .social__link-zen {
      background: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 48 48' style='enable-background:new 0 0 48 48;' xml:space='preserve' fill='%23000000'%3E %3Cstyle type='text/css'%3E .st0{fill-rule:evenodd;clip-rule:evenodd;} %3C/style%3E %3Cpath class='st0' d='M23.7,0C9.1,0,0,12.6,0,23.7c10.6-0.3,15.5-0.6,19.4-4.4C23.1,15.5,23.4,10.6,23.7,0z M0,24.3 c10.6,0.3,15.5,0.6,19.4,4.4c3.8,3.9,4.1,8.7,4.4,19.4C10.1,48,0,36.5,0,24.3z M24.3,48c0.3-10.6,0.6-15.5,4.4-19.4 c3.9-3.8,8.7-4.1,19.4-4.4C48,37,37.3,48,24.3,48z M24.3,0c0.3,10.6,0.6,15.5,4.4,19.4c3.9,3.8,8.7,4.1,19.4,4.4 C48,12.3,38.6,0,24.3,0z'/%3E %3C/svg%3E"); }
    .social__link:hover {
      opacity: 1; }
