@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.search__input {
  width: 155px;
  height: 25px;
  border: 1px solid #9f9f9f;
  margin-right: 8px; }
  @media all and (max-width: 991px) {
    .search__input {
      width: 100%; } }
