@charset "UTF-8";
/*WCAG-PANEL*/
.wcag-panel {
  display: none;
  height: 0;
  overflow: hidden;
  position: relative;
  font-size: 18px;
  font-family: 'Arial', 'sans-serif';
  line-height: 24px;
  background-color: #e8e8e8;
  box-sizing: border-box;
  z-index: 999;
  letter-spacing: normal;
  color: #000;
  /* Строки с отступом между собой, на случай, если их будет несколько */
  /* Скрытый блок с ссылкой, которая отображается только если сфокусировать элемент с клавиатуры */
  /* Колонки с управляющими элементами */
  /* Колонка с настройками */
  /* Элемент-контейнер переключаетелей */
  /* Кнопка переключения выпадающего меню */
  /* Ссылки в шапке сайта. Для них используем !important, чтобы стили сайта не влияли на отображение */ }
  .wcag-panel * {
    box-sizing: border-box; }
  .wcag-panel__wrapper {
    margin: 0 auto;
    padding: 17px 15px; }
  .wcag-panel__row {
    position: relative; }
    .wcag-panel__row:not(:last-child) {
      margin-bottom: 15px; }
  .wcag-panel__hidden-control {
    position: absolute;
    right: 100%;
    width: 150px;
    top: 50%;
    transform: translateY(-50%); }
    .wcag-panel__hidden-control a:not(:focus) {
      opacity: 0; }
  .wcag-panel__col {
    display: inline-block;
    vertical-align: bottom;
    padding: 0 11px; }
    .wcag-panel__col:first-child {
      padding-left: 0; }
    .wcag-panel__col:last-child {
      padding-right: 0; }
    .wcag-panel__col_cont-link {
      vertical-align: bottom;
      padding-bottom: 8px; }
  .wcag-panel__settings {
    float: right;
    margin-top: 27px; }
  .wcag-panel__switcher {
    font-size: 0;
    overflow: hidden;
    border: 1px solid black !important;
    border-radius: 3px; }
  .wcag-panel__switcher-label {
    vertical-align: baseline;
    margin-bottom: 3px;
    display: inline-block; }
  .wcag-panel__switcher-btn {
    padding: 0 10px;
    height: 38px;
    font-weight: normal;
    margin-bottom: 0;
    background-color: #fff;
    cursor: pointer;
    font-size: 18px;
    line-height: 38px;
    border: 0;
    display: inline-block;
    position: relative; }
    .wcag-panel__switcher-btn:not(:last-child) {
      border-right: 1px solid black; }
    .wcag-panel__switcher-btn:focus {
      background: #000 !important;
      color: #fff !important;
      box-shadow: inset 0 0 0 2px #ffa; }
  .wcag-panel__dropdown-toggle {
    position: relative;
    padding: 0 27px 0 10px;
    height: 36px;
    line-height: 36px; }
    .wcag-panel__dropdown-toggle:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -2px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-top: 5px solid black;
      border-right: 6px solid transparent; }
    .wcag-panel__dropdown-toggle:focus:after {
      border-top-color: white; }
    .wcag-panel__dropdown-toggle[aria-expanded="true"] {
      background: black;
      color: white; }
    .wcag-panel__dropdown-toggle[aria-expanded="true"]:after {
      border-top: 0;
      border-bottom: 5px solid white; }
  .wcag-panel__dropdown {
    height: 0;
    overflow: hidden;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: 18px;
    text-align: left;
    list-style: none;
    background-color: #f3f3f3;
    background-clip: padding-box;
    border-top: 1px solid #ccc !important;
    outline: none !important;
    z-index: 1000; }
    .wcag-panel__dropdown .wcag-panel__wrapper {
      height: 100%;
      width: 100%;
      padding: 27px 15px; }
  .wcag-panel__flag-button {
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    display: inline-block;
    margin-bottom: 0; }
    .wcag-panel__flag-button:not(:last-child) {
      margin-right: 7px; }
  .wcag-panel__default-link {
    font-size: 18px;
    line-height: 20px;
    color: black !important;
    text-decoration: none !important;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 1px;
    border-color: #0000FF !important; }
    .wcag-panel__default-link:focus {
      background: transparent !important;
      outline-color: #0000ff !important; }
  .wcag-panel input[type="radio"],
  .wcag-panel input[type="checkbox"] {
    /* "Скрываем" их таким образом, а не {display: none} чтобы focus с клавиатуры работал */
    position: absolute;
    left: -99999px; }
    .wcag-panel input[type="radio"]:checked + .wcag-panel__switcher-btn,
    .wcag-panel input[type="checkbox"]:checked + .wcag-panel__switcher-btn {
      color: white;
      background-color: black; }
    .wcag-panel input[type="radio"]:focus + .wcag-panel__switcher-btn,
    .wcag-panel input[type="checkbox"]:focus + .wcag-panel__switcher-btn {
      color: white;
      background: black;
      box-shadow: inset 0 0 0 2px #ffa;
      outline: 0; }

/*FLAG-BUTTON*/
.flag-button {
  position: relative;
  width: 31px;
  height: 38px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #000 !important;
  /* Красные точки на выбранных кнопках */ }
  input:focus + .flag-button {
    box-shadow: inset 0 0 0 2px #ffa;
    background: black;
    color: white; }
  .flag-button:before, .flag-button:after {
    display: none;
    content: '';
    position: absolute;
    top: -3px;
    left: 11px;
    width: 8px;
    height: 8px;
    background: #fe0d0d;
    border-radius: 50%; }
    input:checked + .flag-button:before, input:checked + .flag-button:after {
      display: block; }
  .flag-button:after {
    bottom: -3px;
    top: auto; }
  .flag-button_white {
    background: #fff; }
  .flag-button_black {
    color: #fff;
    background: #000; }
  .flag-button_blue {
    background: #0000ff;
    color: #ffff00; }
  .flag-button_beige {
    background: #f7f3d6;
    color: #4d4b43; }
  .flag-button_brown {
    background: #3b2716;
    color: #a9e44d; }
