@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.slider {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: hidden;
  min-height: 78px; }
  .slider__item {
    padding: 0 35px; }
  .slider__arrow {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.lSAction > a {
  background-image: none;
  top: 54%; }

.lSAction > .lSNext {
  background: #d1c4a8;
  right: 0; }

.lSAction > .lSPrev {
  background: #d1c4a8;
  left: 0; }

.inline-icon-arrow-right_left {
  transform: rotate(180deg) translate(50%, 50%);
  margin-top: 15px;
  margin-left: 15px; }

.inline-icon-arrow-right {
  margin-left: 5px; }

.inline-icon-arrow-right_left {
  margin-right: 5px;
  margin-left: 15px; }

.banner-local__item {
  position: relative; }

.banner-local__title {
  position: absolute;
  top: 90px;
  left: 28px;
  background: #452b1e;
  color: #efebe2;
  width: 200px;
  text-align: center;
  margin: 0 auto;
  font-weight: bold; }

.slider-video__link {
  position: relative; }
  .slider-video__link::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    top: 50%;
    margin-top: -30px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect width='60' height='60' rx='30' fill='white' fill-opacity='0.4'/%3E %3Cpath d='M43 30L23.5 40.3923L23.5 19.6077L43 30Z' fill='white'/%3E %3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain; }
