@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.inline-icon-arrow-right,
.inline-icon-arrow-left {
  display: inline-block;
  content: '';
  height: 30px;
  width: 20px;
  transition: all .15s; }

.inline-icon-arrow-right_size_small {
  height: 10px;
  width: 15px; }

.inline-icon-arrow-top-right {
  position: relative;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  font-size: 0.8em; }
  .inline-icon-arrow-top-right:after {
    content: "↗"; }

.inline-icon-plus {
  position: relative;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  font-size: 0.8em; }
  .inline-icon-plus:after {
    content: "╋"; }

.inline-icon-cross {
  position: relative;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  font-size: 0.8em; }
  .inline-icon-cross:after {
    content: "╳"; }
