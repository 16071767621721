@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.voteResult-wrap {
  display: flex;
  justify-content: space-between;
  width: 350px; }
  @media all and (max-width: 575px) {
    .voteResult-wrap {
      width: 200px; } }

.voteResult-title {
  display: block;
  margin-bottom: 10px; }

.voteResult-graph {
  flex-basis: 300px;
  width: 300px;
  min-width: 300px;
  height: 25px;
  margin-right: 20px;
  background: #FBFBEE;
  border: 1px solid #084000;
  box-sizing: border-box; }
  @media all and (max-width: 575px) {
    .voteResult-graph {
      flex-basis: 200px;
      width: 200px;
      min-width: 200px; } }

.voteResult-active {
  display: block;
  height: 100%;
  background: #efebe2; }

.voteResult-percent {
  flex-grow: 1;
  text-align: right; }
