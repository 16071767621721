.nobr {
  white-space: nowrap; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.uppercase {
  text-transform: uppercase; }

.big-text {
  font-size: 1.25em; }

.small-text {
  font-size: 0.75em; }
