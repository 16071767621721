@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.active-link {
  margin-top: 35px; }
  .active-link__title {
    text-transform: uppercase;
    font-size: 1.15rem;
    font-weight: normal; }
  .active-link__wrapper {
    display: flex;
    flex-direction: column; }
  .active-link__item {
    background: #efebe2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    margin-bottom: 5px;
    height: 60px; }
  .active-link__icon {
    display: block;
    width: 35px;
    height: 30px;
    background-repeat: no-repeat;
    margin-right: 10px;
    flex-shrink: 0; }
    .active-link__icon-cfera {
      background-image: url(img/icon_drevo.png); }
    .active-link__icon-budjet {
      background-image: url(img/icon_coin.png); }
    .active-link__icon-plan {
      background-image: url(img/icon_calendar.png); }
