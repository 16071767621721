@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
html[data-color="white"] .region-map {
  background: #f5f5f5;
  background-size: cover; }
  html[data-color="white"] .region-map__title {
    color: #000; }
  html[data-color="white"] .region-map__subtitle {
    color: #000; }
  html[data-color="white"] .region-map__item circle {
    fill: #000; }
  html[data-color="white"] .region-map__item:hover circle, html[data-color="white"] .region-map__item_active circle {
    fill: #f5f5f5; }

html[data-color="black"] .region-map {
  background: #333;
  background-size: cover; }
  html[data-color="black"] .region-map__title {
    color: #fff; }
  html[data-color="black"] .region-map__subtitle {
    color: #fff; }
  html[data-color="black"] .region-map__item text {
    fill: #fff; }
  html[data-color="black"] .region-map__item circle {
    fill: #f5f5f5; }
