@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.region-map {
  display: block;
  position: relative;
  padding: 85px 0; }
  .region-map__item {
    position: relative; }
  @media (max-width: 991px) {
    .region-map {
      padding: 30px 0; } }
  .region-map__title {
    display: inline-block;
    width: 260px;
    margin-bottom: 30px;
    text-align: left;
    font-size: 3rem;
    line-height: 1.7rem; }
    @media (min-width: 380px) {
      .region-map__title {
        width: 100%; } }
    @media (min-width: 768px) {
      .region-map__title {
        padding-left: 3rem; } }
  .region-map__name {
    display: inline-block;
    width: 100%;
    padding-top: 60px; }
    @media all and (max-width: 991px) {
      .region-map__name {
        padding-top: 0; } }
  .region-map__ul {
    display: inline-block;
    width: 260px;
    text-align: left;
    list-style: none;
    padding-left: 0; }
    @media (min-width: 380px) {
      .region-map__ul {
        width: 320px; } }
    @media all and (max-width: 991px) {
      .region-map__ul {
        width: 100%; } }
    @media (min-width: 768px) {
      .region-map__ul {
        padding-left: 3rem; } }
  .region-map__li {
    font-size: 0.8rem;
    text-decoration: underline;
    cursor: pointer; }
    .region-map__li:not(:last-child) {
      margin-bottom: 15px; }
    @media (min-width: 576px) {
      .region-map__li {
        font-size: 1rem;
        line-height: 29px; }
        .region-map__li:not(:last-child) {
          margin-bottom: 0; } }
    .region-map__li:hover a {
      color: #3b2c25; }
    .region-map__li a {
      width: 100%;
      display: inline-block;
      text-decoration: underline; }
  .region-map__map {
    position: relative;
    vertical-align: top;
    width: 100%;
    background: url(img/map_bg.png) no-repeat center; }
    .region-map__map path {
      fill: #dee7d1;
      stroke: #dee7d1; }
    .region-map__map circle {
      fill: #a31d24;
      stroke: #a31d24; }
    .region-map__map text {
      fill: #2c2c2c;
      font-size: .7rem;
      font-weight: 500; }
    .region-map__map-link {
      position: absolute;
      display: block;
      padding: 2px 4px;
      font-size: 0.85rem;
      border-radius: 3px; }
    .region-map__map-link#parabelskoe {
      top: 115px;
      left: 191px;
      font-size: 1rem; }
      .region-map__map-link#parabelskoe::after {
        content: '';
        position: absolute;
        width: 57px;
        height: 57px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='57' height='57' viewBox='0 0 57 57' xmlns='http://www.w3.org/2000/svg' fill='%23b5cb99' stroke='%23b5cb99'%3E %3Cpath d='M10.5486 17.2618L2.81796 21.4964L0.573547 25.8555L16.4583 27.9728L20.625 31.9583L21.6667 38.2083L27.7556 40.2917L31.0417 44.4583L33.125 51.75L37.2917 54.875L43.5417 55.7468L48.75 52.8823L53.9583 50.6404L56.0417 46.5417L53.9583 41.3333L48.75 40.2917L45.625 34.0417L39.375 27.9728V19.4583L31.0417 17.2618L33.125 12.0308L27.7556 7.04892V2.06704H24.015L22.5187 3.8107L15.7855 0.821564L12.793 12.0308L9.80047 15.7672L10.5486 17.2618Z' stroke-linejoin='round'/%3E %3Ccircle cx='20.625' cy='21.5417' r='6' fill='%23a31d24' stroke='%23a31d24' stroke-linejoin='round'/%3E %3C/svg%3E");
        background-repeat: no-repeat; }
    .region-map__map-link#zavodskoe {
      top: 142px;
      left: 179px; }
      .region-map__map-link#zavodskoe::after {
        content: '';
        position: absolute;
        width: 79px;
        height: 73px;
        left: 50px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='79' height='73' viewBox='0 0 79 73' fill='%23b5cb99' xmlns='http://www.w3.org/2000/svg' stroke='%23b5cb99'%3E %3Cpath d='M41.5736 0.85553L39.3292 5.21468L0.997375 29.875L3.29166 71.5417H26.2083H28.2917L47.0417 56.9583L50.8005 50.7083L61.625 48.625L65.7917 38.2083L78.2917 29.875L74.125 26.75L72.0417 19.4583L68.7556 15.2917L62.6667 13.2083L61.625 6.95833L57.4583 2.97283L41.5736 0.85553Z' stroke-linejoin='round'/%3E %3Ccircle cx='55.375' cy='31.9583' r='4.70833' fill='%23a31d24' stroke='%23a31d24' stroke-linejoin='round'/%3E %3C/svg%3E");
        background-repeat: no-repeat; }
    .region-map__map-link#narymskoe {
      top: 104px;
      left: 350px; }
      .region-map__map-link#narymskoe::after {
        content: '';
        position: absolute;
        width: 70px;
        height: 67px;
        left: -50px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='70' height='67' viewBox='0 0 70 67' fill='%23e8dec3' xmlns='http://www.w3.org/2000/svg' stroke='%23e8dec3'%3E %3Cpath d='M64.8453 60.6404L68.8354 41.4601H60.2916L61.6034 20.0833L36.3333 8.62499L29.0416 0.599304L24.9451 1.85416L7.23936 9.32698L2.25184 10.0743L0.755554 12.067V17.0489L6.12496 22.0308L4.04163 27.2618L12.375 29.4583V37.9728L18.625 44.0417L21.75 50.2917L26.9583 51.3333L35.2916 50.2917L37.375 55.5L45.7083 56.5417L50.9166 61.75L64.4713 65.7468L65.3441 62.8822L64.8453 60.6404Z' stroke-linejoin='round'/%3E %3Ccircle cx='24.875' cy='29.4583' r='4.70833' fill='%23a31d24' stroke='%23a31d24' stroke-linejoin='round'/%3E %3C/svg%3E");
        background-repeat: no-repeat; }
    .region-map__map-link#novoselcovskoe {
      top: 176px;
      right: 90px; }
      .region-map__map-link#novoselcovskoe::after {
        content: '';
        position: absolute;
        width: 49px;
        height: 42px;
        right: 101px;
        top: -18px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='49' height='42' viewBox='0 0 49 42' fill='%23b5cb99' xmlns='http://www.w3.org/2000/svg' stroke='%23b5cb99'%3E %3Cpath d='M48.3466 20.6078L47.5985 19.6114L48.4713 16.7469L34.9166 12.75L29.7083 7.54169L21.375 6.50002L19.2916 1.29169L10.9583 2.33335L13.0416 7.54169L10.9583 11.6404L5.74996 13.8823L0.541626 16.7469V19.6114L10.9583 28.375L7.83329 30.4584L10.3875 40.875L27.6483 33.3116H30.6409L35.8778 26.0879L46.601 28.0806L45.6034 22.6006L48.3466 20.6078Z' stroke-linejoin='round'/%3E %3Ccircle cx='19.2916' cy='22.125' r='4.70833' fill='%23a31d24' stroke='%23a31d24' stroke-linejoin='round'/%3E %3C/svg%3E");
        background-repeat: no-repeat; }
    .region-map__map-link#staricinskoe {
      top: 215px;
      left: 139px; }
      .region-map__map-link#staricinskoe::after {
        content: '';
        position: absolute;
        width: 59px;
        height: 44px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='59' height='44' viewBox='0 0 59 44' fill='%23b5cb99' xmlns='http://www.w3.org/2000/svg' stroke='%23b5cb99'%3E %3Cpath d='M0.916687 0.541687H35.2917H58.2084V43.25H0.916687V0.541687Z' stroke-linejoin='round'/%3E %3Ccircle cx='37.375' cy='23.4583' r='4.70833' fill='%23a31d24' stroke='%23a31d24' stroke-linejoin='round'/%3E %3C/svg%3E");
        background-repeat: no-repeat; }
  .region-map__item:hover, .region-map__item_active {
    color: #3b2c25 !important;
    text-decoration: underline !important; }
