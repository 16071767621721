@charset "UTF-8";
/* Переменные со значениями для разных цветовых схем */
/* Границы блоков */
/* "основной" фон */
/* "Дополнительный" фон, чтобы выделять блоки на фоне основного */
/* Ссылки */
/* Текст */
/* Подчеркивания ссылок */
/* Инвертированный текст */
/* Инвертированный фон */
/* Фон, цвет текста и outline границы элементов в фокусе */
/* Фон и цвет текста элементов при наведении */
