.not-underline p, .not-underline h1, .not-underline h2, .not-underline h3, .not-underline h4, .not-underline h5, .not-underline h6, .not-underline ul, .not-underline ol {
  max-width: 80ch; }
  .not-underline p:last-child, .not-underline h1:last-child, .not-underline h2:last-child, .not-underline h3:last-child, .not-underline h4:last-child, .not-underline h5:last-child, .not-underline h6:last-child, .not-underline ul:last-child, .not-underline ol:last-child {
    margin-bottom: 0; }

.not-underline li:not(:last-child) {
  margin-bottom: 0.5rem; }

.not-underline li > ul,
.not-underline li > ol {
  margin-top: 0.5rem; }
