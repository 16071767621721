html[data-color="white"] .checkbox-text::before {
  background-color: #f5f5f5 !important;
  border-color: #aaa; }

html[data-color="white"] .checkbox-text::after {
  border-color: #555 !important; }

html[data-color="white"] .checkbox-label:hover .checkbox-text,
html[data-color="white"] .checkbox-input:focus ~ .checkbox-text {
  color: #555 !important; }

html[data-color="white"] .checkbox-label:hover .checkbox-text::before,
html[data-color="white"] .checkbox-input:checked ~ .checkbox-text::before {
  border-color: #aaa; }

html[data-color="white"] .checkbox-input:focus ~ .checkbox-text::before {
  outline: 2px solid #0000ff;
  background-color: #fff02a !important;
  border: none;
  border-radius: 0; }

html[data-color="black"] .checkbox-text::before {
  background-color: #000 !important;
  border-color: #ccc; }

html[data-color="black"] .checkbox-text::after {
  border-color: #fff; }

html[data-color="black"] .checkbox-label:hover .checkbox-text,
html[data-color="black"] .checkbox-input:focus ~ .checkbox-text {
  color: #ccc !important; }

html[data-color="black"] .checkbox-label:hover .checkbox-text::before,
html[data-color="black"] .checkbox-input:checked ~ .checkbox-text::before {
  border-color: #ccc; }

html[data-color="black"] .checkbox-input:focus ~ .checkbox-text::before {
  outline: 2px solid #0000ff;
  background-color: #fff02a !important;
  border: none;
  border-radius: 0; }

html[data-color="black"] .checkbox-input:checked:focus ~ .checkbox-text::after {
  border-color: #000; }
