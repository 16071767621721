@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  position: relative; }
  .menu > li {
    position: relative;
    display: inline-block; }
    .menu > li:last-child {
      margin-left: auto; }
    .menu > li:hover {
      background: #d1c4a8; }
  .menu__item {
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem; }
    .menu__item:hover {
      color: #3b2c25 !important; }
  .menu > li:hover .menu__dropdown {
    display: block; }
  .menu__dropdown {
    position: absolute;
    display: none;
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px 10px;
    margin: 0;
    background: #d1c4a8;
    top: 50px;
    left: 0;
    z-index: 3; }
    .menu__dropdown li {
      display: block;
      padding: 5px; }
    .menu__dropdown a {
      display: block; }
      .menu__dropdown a:hover {
        background: #d1c4a8;
        color: #3b2c25;
        text-decoration: underline; }

.menu > li > [role="menu"],
.navigation > div > [role="menu"] {
  position: fixed;
  transform: translateY(0);
  transition: all 0.25s;
  list-style: none;
  top: 470px;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  overflow-y: auto; }

.wcag_visible .menu > li > [role="menu"],
.wcag_visible .navigation > div > [role="menu"] {
  top: 267px; }

.menu > li > [aria-hidden="true"],
.navigation > div > [aria-hidden="true"] {
  opacity: 0;
  transform: translateY(20px); }

body {
  transition: top 0.25s; }

.menu-open {
  position: fixed;
  overflow-y: scroll;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: top 0.25s; }

.burger-main-container .menu__item {
  justify-content: center; }
