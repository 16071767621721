html[data-color="white"] .faq-form input,
html[data-color="white"] .faq-form textarea {
  background-color: #f5f5f5;
  color: #555; }

html[data-color="white"] .last-questions {
  background-color: #fff;
  color: #555; }

html[data-color="black"] .faq-form input,
html[data-color="black"] .faq-form textarea {
  background-color: #000;
  color: #ccc; }

html[data-color="black"] .last-questions {
  background-color: #1d1d1d;
  color: #ccc; }
