html[data-color="white"] .btn {
  background-color: #555;
  color: #fff; }

html[data-color="white"] .btn:hover {
  color: #fff;
  outline: 2px solid #0000ff; }

html[data-color="white"] .btn:focus,
html[data-color="white"] .btn:target,
html[data-color="white"] .btn:active {
  outline: 2px solid #0000ff;
  background-color: #fff02a !important;
  color: #111 !important; }

html[data-color="black"] .btn {
  background-color: #ccc;
  color: #1d1d1d; }

html[data-color="black"] .btn:hover {
  color: #1d1d1d;
  outline: 2px solid #ccc; }

html[data-color="black"] .btn:focus,
html[data-color="black"] .btn:target,
html[data-color="black"] .btn:active {
  outline: 2px solid #ccc;
  background-color: #fff02a !important;
  color: #111 !important; }

html:not([data-color="default"]) .btn,
html:not([data-color="default"]) .btn:active {
  display: inline-block;
  font-weight: bold;
  border: none;
  border-radius: 0;
  text-decoration: none;
  text-align: center;
  font-size: inherit; }
