html[data-color="white"] .header__top, html[data-color="white"] .header__main {
  background-color: #f5f5f5;
  color: #555; }

html[data-color="black"] .header__top, html[data-color="black"] .header__main {
  background-color: #000;
  color: #ccc; }

html:not([data-fontsize="normal"]) .header__top-column {
  flex: 0 0 50%;
  max-width: 50%; }

html[data-fontsize="large"][data-kerning="big"] .header__main {
  padding-top: 20px; }
