@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.wcag-btn {
  margin-right: 30px;
  font-weight: 300;
  background-color: transparent;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

html[data-color="white"] .wcag-btn {
  color: #222;
  background-color: transparent;
  border-radius: 0; }

html[data-color="white"] .wcag-btn:hover {
  outline: 2px solid #0000ff; }

html[data-color="white"] .wcag-btn:focus,
html[data-color="white"] .wcag-btn:target,
html[data-color="white"] .wcag-btn:active {
  background-color: #fff02a;
  outline: 2px solid #0000ff; }

html[data-color="black"] .wcag-btn {
  border-radius: 0; }

html[data-color="black"] .wcag-btn:hover {
  outline: 2px solid #ccc; }

html[data-color="black"] .wcag-btn:focus,
html[data-color="black"] .wcag-btn:target,
html[data-color="black"] .wcag-btn:active {
  background-color: #fff02a;
  outline: 2px solid #ccc; }
