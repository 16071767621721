html[data-color="white"] .card-body {
  background-color: #f5f5f5;
  color: #555; }

html[data-color="black"] .card-body {
  background-color: #000;
  color: #ccc; }

html:not([data-color="default"]) .card-body {
  border-bottom: none; }

html:not([data-color="default"]) .card-body:hover {
  box-shadow: none; }
