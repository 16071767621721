@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.sidebar-nav {
  box-shadow: -1px -3px 24px 2px rgba(209, 196, 168, 0.32);
  font-size: 1em;
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 10px; }
  @media (max-width: 9926px) {
    .sidebar-nav {
      width: 100%; } }
  .sidebar-nav__item {
    position: relative; }
    .sidebar-nav__item:not(:last-child) {
      margin-bottom: 10px; }
    .sidebar-nav__item:hover > .sidebar-nav__dropdown {
      display: block; }
  .sidebar-nav__wrapper {
    margin: 0;
    padding: 0;
    list-style: none; }
  .sidebar-nav li {
    display: block;
    padding: 5px; }
    .sidebar-nav li:hover {
      background: #d1c4a8; }
  .sidebar-nav a:not(.btn) {
    display: block; }
  .sidebar-nav__dropdown {
    display: none;
    width: 240px;
    position: absolute;
    top: 0;
    left: 100%;
    background: #d1c4a8;
    z-index: 999;
    padding: 15px 10px; }
    .sidebar-nav__dropdown a {
      display: block; }
      .sidebar-nav__dropdown a:hover {
        text-decoration: underline; }
