@media all and (min-width: 768px) {
  .mobile-only {
    display: none; } }

@media all and (max-width: 767px) {
  .tab-only {
    display: none; } }

@media all and (min-width: 1024px) {
  .tab-only {
    display: none; } }

@media all and (max-width: 1023px) {
  .desktop-only {
    display: none; } }

@media all and (max-width: 767px) {
  .tab-and-desktop-only {
    display: none; } }

@media all and (min-width: 1024px) {
  .tab-and-mobile-only {
    display: none; } }

@media all and (max-width: 1366px) {
  .fullhd-only {
    display: none; } }
