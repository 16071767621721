@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
@media all and (max-width: 575px) {
  .news > div {
    flex-direction: column; } }

.news__link {
  font-size: 0.8rem; }

.news-item__link:hover {
  text-decoration: none !important;
  color: inherit !important; }

.news-item__preview {
  float: left;
  width: 100px;
  height: auto;
  margin-right: 15px;
  border-radius: 4px; }
  @media all and (max-width: 575px) {
    .news-item__preview {
      width: 100%; } }

.news-item__description {
  display: block;
  overflow: hidden;
  min-height: 80px; }
  @media all and (max-width: 575px) {
    .news-item__description {
      width: 100%; } }

.news-item__title {
  margin: 0 0 0.5rem;
  font-size: 1.125rem;
  line-height: 1.44;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: justify; }
  .news-item__title:hover {
    text-decoration: none;
    color: #797979; }

.news-item__date {
  font-size: 0.8rem; }
  .news-item__date-icon {
    display: block;
    float: left;
    width: 17px;
    height: 17px;
    background: url(img/icon_calendar.png) no-repeat center;
    background-size: contain;
    margin-right: 15px;
    margin-top: 3px; }
